<template>
  <div class="add_apply">
    <!-- :example="exampleImg || require(`@/assets/images/examplePic/example${formData.type === '2' ? '13' : '1'
      }.png`)" -->
    <div class="bx_item">
      <div class="fs-40">服务说明</div>
      <div class="box fs-28 rich-text" v-html="instypeInfo.instype_explain"></div>
    </div>
    <template v-if="orderInfo.imei !== formData.imei">
      <div class="bx_item">
        <div class="fs-40 mt-50">
          设备正面照<span style="color: #e86161">*</span>
        </div>
        <div class="box fs-28">
          <p>1.请在拨号界面输入*#06#串码或序列号界面拍照；</p>
          <p>2.请参考示例图拍摄，确保手机拍摄完整，串码一致</p>
        </div>
      </div>
      <pic-uploader :src="formData.tem_img" :example="require(`@/assets/images/examplePic/example9.png`)" style="padding-left: 15px" @change="(url) => (formData.tem_img = url)"></pic-uploader>
    </template>
    <div class="bx_item form_box">
      <div class="fs-40 mt-50">
        用户信息<span style="color: #e86161">*</span>
      </div>
      <div>
        <van-form validate-first @submit="onSubmit" class="form_wid">
          <scan-code title="IMEI号或序列号" placeholder="请输入手机IMEI码或扫码" v-model="formData.imei" maxlength="24" />
          <select-search-cell title="权益类型" placeholder="请选择权益类型" v-model="formData.instype_id" :options="instypeList"
            id-key="instype_id" label-key="instype_name" />
          <select-search-cell title="新机品牌" placeholder="请选择新机品牌" v-model="formData.brand_id" :options="brandList"
            id-key="brand_id" label-key="brand" />
          <select-search-cell title="手机型号" placeholder="请输入查询或选择型号" v-model="formData.model_id" :options="modelList"
            id-key="model_id" label-key="model_name" />
          <select-search-cell title="手机规格" placeholder="请输入查询或选择规格" v-model="formData.ram_size" :options="ramsizeList"
            id-key="ram_size" label-key="ram_size" />
          <select-search-cell title="年限" placeholder="请输入查询或选择年限" v-model="formData.valid_stage" :options="stageList" />
          <money v-if="priceInfo" v-model="priceInfo.cost_price" disabled title="服务价格" />
          <div class="btn_sub">
            <my-button class="confirm-btn" :type="isFull ? 'primary' : 'default'" :disabled="!isFull"
              native-type="submit">提交申请
            </my-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { renewalType, renewalPrice, renewal } from '@/api/Renew'
import wx, { verification } from '@/utils/wxPermissionValidation'
export default {
  data() {
    return {
      initializing: false,
      orderInfo: {},
      formData: {
        tem_img: '',
        instype_id: '',
        valid_stage: '',
        brand_id: '',
        model_id: '',
        ram_size: '',
        // order_id: '',
        // openid: '',
        imei: ''
      },
      priceInfo: null,
      instypeList: [],
      brandList: [],
      modelList: [],
      ramsizeList: [],
      stageList: [
        { id: '一年', label: '一年' },
        // { id: '两年', label: '两年' },
      ],
    }
  },
  watch: {
    'formData.imei': {
      handler(v) {
        const { imei, brand_id, model_id, ram_size, valid_stage } = this.orderInfo
        if (v === imei) {
          this.initializing = true
          this.formData.brand_id = brand_id
          this.formData.model_id = model_id
          this.formData.ram_size = ram_size
          // this.formData.valid_stage = valid_stage
          this.$nextTick(() => {
            this.initializing = false
          })
        }
      }
    },
    'formData.brand_id': {
      handler() {
        if (!this.initializing) this.formData.model_id = ''
        this.getPhoneModel()
        this.getPrice()
      }
    },
    'formData.model_id': {
      handler() {
        if (!this.initializing) this.formData.ram_size = ''
        this.getPhoneRam()
        this.getPrice()
      }
    },
    'formData.instype_id': {
      handler() {
        this.getPrice()
      }
    },
    'formData.valid_stage': {
      handler() {
        this.getPrice()
      }
    },
    'formData.ram_size': {
      handler() {
        this.getPrice()
      }
    },
  },
  computed: {
    instypeInfo() {
      return this.instypeList.find(item => item.instype_id == this.formData.instype_id) || {}
    },
    isFull() {
      let full = true
      Object.entries(this.formData).forEach(([k, v]) => {
        if (k === 'tem_img') return
        if (!full) return
        if (!v) full = false
      })
      return full
    }
  },
  created() {
    this.insuranceType()
    this.getPhoneBrand()
    this.getOrderInfo()
  },
  methods: {
    getOrderInfo() {
      this.$http.getOrderInfo({ order_id: this.$route.query.order_id }).then((res) => {
        console.log(res.data)
        this.orderInfo = res.data?.order || {};
        this.formData.imei = this.orderInfo.imei //用于回填imei  watch里面imei监听到和订单详情里的imei一致的话就会回填新机品牌、手机型号、手机规格
      })
    },
    async onSubmit() {
      const res = await renewal({
        ...this.formData,
        order_id: this.$route.query.order_id,
        openid: localStorage.getItem('openid')
      })
      await verification(["chooseWXPay"])
      console.log(res.data.data)
      wx.chooseWXPay({
        timestamp: res.data.data.timeStamp,
        nonceStr: res.data.data.nonceStr,
        package: res.data.data.package,
        signType: res.data.data.signType,
        paySign: res.data.data.paySign,
        success: (result) => {
          this.$myDialog({
            title: result?.msg || "支付成功",
          });
          setTimeout(() => {
            this.$router.back()
          }, 1000);
        },
        fail: (err) => {
          console.log(err)
        }
      })
    },
    insuranceType() {
      renewalType().then((res) => {
        this.instypeList = res.data;
        this.formData.instype_id = res.data[0].instype_id
      });
    },
    //品牌数组请求
    getPhoneBrand() {
      this.$http.getPhoneBrand({ type: 0 }).then((res) => {
        this.brandList = res.data.brandList
      });
    },
    //品牌下手机型号数组请求
    getPhoneModel() {
      this.$http.getPhoneModel({ brand_id: this.formData.brand_id }).then((res) => {
        this.modelList = res.data
      });
    },
    // 手机型号下的手机规格
    getPhoneRam() {
      this.$http.getPhoneRam({ model_id: this.formData.model_id }).then((res) => {
        this.ramsizeList = res.data
      });
    },
    getPrice() {
      const { instype_id, valid_stage, brand_id, model_id, ram_size } = this.formData
      if (!instype_id || !valid_stage || !brand_id || !model_id || !ram_size) {
        this.priceInfo = null
        return
      }
      renewalPrice({ instype_id, valid_stage, brand_id, model_id, ram_size }).then(res => {
        this.priceInfo = res.data
      })
    }
  },
}
</script>

<style scoped lang="less">
.bx_item {
  width: calc(100vw - 60px);
  margin: 20px auto;

  .box {
    line-height: 44px;
    margin-top: 20px;
    padding: 28px 25px;
    background: #f6f8fe;
    border-radius: 16px;
    color: #333333;
    font-family: PingFang SC;
    font-weight: 400;
  }
}

::v-deep .rich-text img {
  max-width: 100%;
}

.btn_sub {
  padding: 50px 0;
}

.confirm-btn {
  width: calc(100vw - 150px);
  margin: 30px auto 0;
}
</style>